/* eslint-disable new-cap */
/* eslint-disable no-restricted-syntax */
"use strict";
var Lightpick = require('../js/components/lightpick');
var moment = require('moment');
/* global SmartOrderRefillSettings */
(function ($) {
    /**
     *  Property of OSF Global Services, Inc., (with its brand OSF Commerce). OSF remains the sole owner of all right, title and interest in the software.
     *  Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
     */
    /* ***** Start of Util section ***** */
    function SmartOrderRefill() {
        // This is intentional
    } // SmartOrderRefill acts as a namespace for related methods

    // Form Validation
    SmartOrderRefill.validateForm = function (element) {
        var formValid = true;
        element.find(".field-wrapper").each(function () {
            var fieldWrapper = $(this);
            var field = fieldWrapper.find("input, select");
            if (field.length > 0) {
                field.removeClass("error");
                fieldWrapper.find("label.error").addClass("hide");
                var validState = field[0].validity;

                if (!validState.valid) {
                    formValid = false;
                    field.addClass("error");
                    if (validState.valueMissing) {
                        fieldWrapper.find("label.error.missing-error").removeClass("hide");
                    } else {
                        fieldWrapper.find("label.error.value-error").removeClass("hide");
                    }
                }
            }
        });
        return formValid;
    };

    // Create Modal Up To Modal Type
    SmartOrderRefill.CreateModal = function (container, options) {
        if (options.buttons && Object.keys(options.buttons).length > 0) {
            var buttons = {};
            for (var key in options.buttons) {
                if (key.indexOf("SOR_GLOBAL_") > -1) {
                    buttons[SmartOrderRefillSettings.Resources[key]] = options.buttons[key];
                } else {
                    buttons[key] = options.buttons[key];
                }
            }
            options.buttons = buttons;
        }
        if (SmartOrderRefillSettings.ModalType === "dialog") {
            $(container).dialog(options);
        } else {
            SmartOrderRefill.BootstrapModal(container, options);
        }
    };

    // Generic Modal Close
    SmartOrderRefill.CloseModal = function () {
        if (SmartOrderRefillSettings.ModalType === "dialog") {
            $(this).closest(".ui-dialog-content").dialog("close");
        } else {
            var modal = $(this).closest(".modal");
            if (modal.length === 0) {
                modal = $(this).find(".modal");
            }
            modal.modal("hide");
        }
    };

    // Bootstrap Modal
    SmartOrderRefill.BootstrapModal = function (container, options) {
        var buttonsMarkUp = "";
        var title = options.title ? options.title : "";
        var width = options.width ? "style=\"max-width:" + options.width + "!important;\"" : "";
        var buttons = options.buttons;
        var context = options.context;
        if (buttons && Object.keys(buttons).length > 0) {
            $(container).off("click", "#sorModalCenter button.action");
            for (var key in buttons) {
                var colorClass =
                    key.toLowerCase() == 'confirm' ||
                    key.toLowerCase() == 'yes' ||
                    key.toLowerCase() == 'go back' || // option for active 'SorCallToCancelEnabled' site prefference only
                    key.toLowerCase() == 'continue to add products' ? "green" : "white";
                buttonsMarkUp += "<button type=\"button\" class=\"btn btn-primary action " + colorClass + "\"  data-id=\"" + key + "\"  data-context=\"" + context + "\">" + key.replace('-', ' ') + "</button>";
                $(container).on("click", "#sorModalCenter button.action[data-id=\"" + key + "\"]", buttons[key]);
            }
        }

        var bootstrap = '';
        var isAjaxModal = $('#my_subscriptions').attr('data-modalurl') != undefined;

        if (isAjaxModal) {
            var isCancel = options.cancel ? '?type=cancel' : '';
            var isAddNewProducts = options.addnewproducts ? '?type=addnewproducts' : '';
            var isRenewSubscription = options.renew ? '?type=renew' : '';
            var url = $('#my_subscriptions').attr('data-modalurl') + isCancel + isAddNewProducts + isRenewSubscription;
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    var $bootstrap = $('<div>' + response + '</div>').find('div#sorModalCenter').clone();

                    if (options.width) {
                        $bootstrap.find('.modal-dialog').attr('max-width', options.width + ' !important');
                    }

                    $bootstrap.find('.modal-header .modal-title').text(title);
                    $bootstrap.find('.modal-body').prepend(container.html());
                    $bootstrap.find('.modal-footer').append(buttonsMarkUp);

                    // Hide reasons for Meal plan cancelation when 'SorCallToCancelEnabled' site prefference enabled
                    if ($('.call-to-cancel').length > 0) {
                        $bootstrap.find('.modal-body .reasons-wrapper').addClass('d-none');
                    }

                    bootstrap = $bootstrap.prop('outerHTML');

                    configureModal(bootstrap, container, options);
                },
                error: function () {
                    isAjaxModal = false;
                }
            });
        } else {
            bootstrap = `<div class="modal fade" id="sorModalCenter" tabindex="-1" role="dialog" aria-labelledby="sorModalCenter" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" ${width}  role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">${title}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            ${container.html()}
                        </div>
                        <div class="modal-footer">
                            ${buttonsMarkUp}
                        </div>
                    </div>
                </div>
            </div>`;

            configureModal(bootstrap, container, options);
        }
    };

    function configureModal(bootstrap, container, options) {
        container.html(bootstrap);
        container.find(".modal").on("shown.bs.modal", function () {
            var subscriptionModals = $("#subscriptionview, #orderview");
            if (subscriptionModals.length > 0 && !container.is(subscriptionModals)) {
                container.siblings(".modal-backdrop").last().css("z-index", "1060");
                container.find(".modal").css("z-index", "1070");
            }
        });
        container.find(".modal").modal({
            backdrop: "static"
        });
        container.find(".modal").modal("show");
        container.find(".modal").on("hidden.bs.modal", function () {
            container.remove();
            if ($("#subscriptionview, #orderview, #error-model").length > 0) {
                $("body").addClass("modal-open");
            }
        });

        var isCancel = options.cancel;
        if (isCancel) {
            $('form#sorCancelSubscription').attr('action', options.url);

            $('select#reason').on('change', function () {
                $(this).removeClass('invalid-value');
                if ($(this).find('option:selected').attr('id') == '11') {
                    $('input#other').parent().removeClass('d-none').addClass('required');
                } else {
                    $('input#other').parent().addClass('d-none').removeClass('required');
                }
            });

            $('input#other').on('focusout', function () {
                if ($(this).val() != '') {
                    $(this).removeClass('invalid-value');
                }
            });

            $('button[data-id="confirm"]').on('click', function (e) {
                e.stopPropagation();
                // notify gtm that the user is going to cancel (event subscription_confirm_cancel)
                $('body').trigger('gtm:confirmCancelSubscription')
                var $form = $('#sorCancelSubscription');

                var formValidity = SmartOrderRefill.validateCancelForm($form);
                if (formValidity) {
                    var data = $form.serialize();
                    var url = $form.attr('action');
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: data,
                        success: function (response) {
                            SmartOrderRefill.CloseModal();
                            if (response.success) {
                                $.spinner().stop();
                                var redirect = $('span.cancelsubscription').attr('data-aftercancel');
                                location.href = redirect;
                            } else {
                                alert(response.error);
                            }
                        }
                    });
                } else if (formValidity != false) {
                    $form.find('#' + formValidity).addClass('invalid-value');
                }
            });
        }

        var isRenewSubscription = options.renew;
        if (isRenewSubscription) {
            initReactivateCalendar();
        }

        $.spinner().stop();
    }

    function initReactivateCalendar() {
        var url = $('#my_subscriptions').data('href');
        var shipment = $('#my_subscriptions').data('shipment');
        var shippingAddress = $('#my_subscriptions').data('shippingaddress');
        var subscriptionId = $('#my_subscriptions').data('subscriptionid');

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'html',
            data: {
                shipment: JSON.stringify(shipment),
                shippingAddress: JSON.stringify(shippingAddress),
                subscriptionId: subscriptionId
            },
            success: function (response) {
                if (typeof response !== 'undefined') {
                    var enabledDates = JSON.parse(response).availableDates;
                    new Lightpick({ //NOSONAR
                        field: document.getElementById('shippingDeliveryDate'),
                        singleDate: true,
                        enabledDates: JSON.parse(enabledDates),
                        minDate: moment().startOf('day').add(1, 'days'),
                        maxDate: moment().startOf('day').add(32, 'days'),
                        dropdowns: {
                            years: {
                                min: Number.parseInt(moment().format('YYYY')),
                                max: Number.parseInt(moment().add(1, 'years').format('YYYY'))
                            }
                        },
                        onSelect: function () {
                            if ($('.error-block') && !$('.error-block').hasClass('d-none')) {
                                $('.error-block').addClass('d-none');
                            }
                        },
                        inline: true
                    });
                }
            }
        });
    }

    SmartOrderRefill.validateCancelForm = function () {
        var $form = $('form#sorCancelSubscription');
        var valid = false;

        if ($form.length != 0) {
            var $reason = $form.find('select#reason option:selected');
            var $other = $form.find('input#other');

            if ($reason.length != 0 && !$reason.hasClass('default-option')) {
                if ($reason.attr('id') == '11') {

                    if ($other.length != 0 && $other.val() != '') {
                        valid = true;
                    } else {
                        valid = 'other';
                    }
                } else {
                    valid = true;
                    $other.val('');
                }
            } else {
                valid = 'reason';
            }
        }
        return valid;
    };

    SmartOrderRefill.limitCharacters = function () {
        $("form").find("textarea[data-character-limit]").each(function () {
            var characterLimit = $(this).data("character-limit");
            var charCountHtml = String.format(SmartOrderRefillSettings.Resources.CHAR_LIMIT_MSG,
                    "<span class=\"char-remain-count\">" + characterLimit + "</span>",
                    "<span class=\"char-allowed-count\">" + characterLimit + "</span>");
            var charCountContainer = $(this).next("div.char-count");
            if (charCountContainer.length === 0) {
                charCountContainer = $("<div class=\"char-count\"/>").insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    };

    SmartOrderRefill.creatContainer = function (selector) {
        var $target,
            id;
        if (jQuery.type(selector) === "string") {
            if (selector.indexOf("#") === 0) {
                $target = $(selector, document);
                $target.selector = selector;
            } else {
                $target = $("#" + selector, document);
                $target.selector = "#" + selector;
            }
        } else if (selector instanceof jQuery) {
            $target = selector;
        }

        if ($target && $target.length === 0) {
            if ($target.selector && $target.selector.indexOf("#") === 0) {
                id = $target.selector.substr(1);
                $target = $("<div>").attr("id", id).appendTo("body");
            }
        }
        return $target;
    };

    function getURLParameter(url, name) {
        const match = RegExp(name + '=' + '(.+?)(&|$)').exec(url);
        return match ? match[1] : null;
    }

    /* ****** End of Util section ****** */


    /* ****** Start of Account section ******* */

    SmartOrderRefill.initAddressChangeForm = function (target, urlview) {
        $(document).off("click", ".changeaddress").on("click", ".changeaddress", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#addresschange"),
                options = SmartOrderRefill.ModalOptions.addressChangeFormOptions(target, urlview, $container);

            $container.load(url, function () {
                SmartOrderRefill.CreateModal($container, options);
                var $form = $("#editAddressForm");

                $("select[name$=\"_changeaddress\"]", $form).on("change", function () {
                    var selected = $(this).children(":selected").first(),
                        selectedAddress = $(selected).data("address");
                    if (!selectedAddress) {
                        return;
                    }
                    fillAddressFields(selectedAddress, $form);
                    SmartOrderRefill.validateForm($form);
                });
            });
        });
    };

    function fillAddressFields(address, $form) {
        for (var field in address) {
            if (field === "ID" || field === "UUID" || field === "key") {
                continue;
            }
            $form.find("[name$=\"" + field.replace("Code", "") + "\"]").val(address[field]);

            if (field === "countryCode") {
                $form.find("[name$=\"country\"]").trigger("change");
                $form.find("[name$=\"state\"]").val(address.stateCode);
            }
        }
    }

    /**
     * @private
     * @function
     * @description initialize dialog for order view
     */
    SmartOrderRefill.initOrderView = function () {
        $(".order.view").on("click", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#orderview");

            $container.load(url, function () {
                SmartOrderRefill.limitCharacters();
                SmartOrderRefill.CreateModal($container, SmartOrderRefill.ModalOptions.orderViewOptions);
                SmartOrderRefill.initAddressChangeForm("#orderview", url);
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize dialog for subscription view
     */
    SmartOrderRefill.initSubscriptionView = function () {
        $(".subscription.view").on("click", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#subscriptionview");

            $container.load(url, function () {
                SmartOrderRefill.limitCharacters();
                SmartOrderRefill.CreateModal($container, SmartOrderRefill.ModalOptions.subscriptionViewOptions);
                SmartOrderRefill.initAddressChangeForm("#subscriptionview", url);
                SmartOrderRefill.initUpdateCreditCardForm();
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize update product quantity
     */
    SmartOrderRefill.initUpdateProductQuantity = function () {
        $(document).on("click", ".update-item", function (e) {
            e.preventDefault();

            var url = $(this).attr("data-link"),
                item = getURLParameter(url, "item"),
                quantity = $(this).parents(".order-section").find("#quantity_" + item).val();
            $.ajax({
                type: "POST",
                url: url,
                data: {
                    quantity: quantity
                }
            }).done(function (response) {
                if (response) {
                    if ($("#qtyError").length) {
                        $("#qtyError").remove();
                    }
                    if (response.success) {
                        window.location = SmartOrderRefillSettings.Urls.manageOrders;
                    } else if (!$("#qtyError").length) {
                        $("<label class=\"error\" id=\"qtyError\">" + response.message + "</label>").insertAfter("#quantity_" + item);
                    }
                } else {
                    SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_QUANTITY_ERROR, false);
                }
            }).fail(function () {
                SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize remove product
     */
    SmartOrderRefill.initRemoveProduct = function () {
        $(document).on("click", ".remove-item", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link");
            $.ajax({
                type: "POST",
                url: url
            }).done(function (response) {
                handleRefillResponse(response);
            }).fail(function () {
                handleRefillError();
            });
        });
    };

    /**
     * Handles the response of the refill update AJAX request.
     *
     * @param {Object} response - The response object from the AJAX request.
     */
    function handleRefillResponse(response) {
        if (response && response.success) {
            window.location = SmartOrderRefillSettings.Urls.manageOrders;
        } else {
            SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_QUANTITY_ERROR, false);
        }
    }

    /**
     * Handles errors that occur during the refill update AJAX request.
     *
     */
    function handleRefillError() {
        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
    }

    /**
     *  Validate input for delivery address
     */
    function validateInput(input) {
        var pattern = input.attr('pattern') ? input.attr('pattern') : null;
        var maxLength = input.attr('maxLength') ? input.attr('maxLength') : null;
        var minLength = input.attr('minLength') ? input.attr('minLength') : null;
        var value = input.val();
        var isMandatory = input.attr('required') == 'required' ? true : false;
        var isValidByAllParams = false;
        var isValidByPattern = false;
        var isValidMinLength = false;
        var isValidMaxLength = false;
        if (value) {
            if (!pattern || new RegExp(pattern).test(value)) {
                isValidByPattern = true;
            }
            if (!minLength || value.length >= minLength) {
                isValidMinLength = true;
            }
            if (!maxLength || value.length <= maxLength) {
                isValidMaxLength = true;
            }
            isValidByAllParams = isValidByPattern && isValidMinLength && isValidMaxLength;
        } else {
            if (!isMandatory) {
                isValidByAllParams = true;
            }
        }

        input.removeClass('is-invalid');
        input.parent().find('.invalid-feedback').hide();
        if (!isValidByAllParams) {
            input.addClass('is-invalid');
            if (!value) {
                input.parent().find('.invalid-feedback.invalid-missing').show();
            } else if (!isValidByPattern) {
                input.parent().find('.invalid-feedback.invalid-pattern').show();
            } else if (!isValidMinLength || !isValidMaxLength) {
                input.parent().find('.invalid-feedback.invalid-length').show();
            }
        }

        return isValidByAllParams;
    }

    SmartOrderRefill.initUpdateSubscription = function () {
        $(document).on('click', '.save-subscription-changes', function (e, data) {
            e.preventDefault();

            // data to update products quantity
            var updateProductModel;
            var updateProductModels = [];
            $('.product-item').each(function () {
                updateProductModel = {
                    orderProductId: $(this).find('input').data('pid'),
                    orderQuantity: $(this).find('.product-quantity .quantity-input').val()
                };
                updateProductModels.push(updateProductModel);
            });

            var deliveryAddress = {};
            var shipmentSelectedOption = $('#shipmentSelector-default').find(':selected');
            var isPostalCodeValid;
            var isAddressOneValid;
            var isAddressTwoValid;
            var isCityValid;
            var isShippingCountryValid;
            if ($('.js-account-address-selector').hasClass('d-none')) {
                isPostalCodeValid = validateInput($('#shippingZipCodedefault'));
                isAddressOneValid = validateInput($('#shippingAddressOnedefault'));
                isAddressTwoValid = validateInput($('#shippingAddressTwodefault'));
                isCityValid = validateInput($('#shippingAddressCitydefault'));
                isShippingCountryValid = validateInput($('#shippingCountrydefault'));
                if (!isPostalCodeValid || !isAddressOneValid || !isAddressTwoValid || !isCityValid || !isShippingCountryValid) {
                    return;
                }
                deliveryAddress = {
                    postalCode: $('#shippingZipCodedefault').val(),
                    address1: $('#shippingAddressOnedefault').val(),
                    address2: $('#shippingAddressTwodefault').val(),
                    city: $('#shippingAddressCitydefault').val(),
                    stateCode: $('#shippingCountrydefault').val()
                };
            } else {
                deliveryAddress = {
                    postalCode: shipmentSelectedOption.data('postal-code'),
                    address1: shipmentSelectedOption.data('address1'),
                    address2: shipmentSelectedOption.data('address2'),
                    city: shipmentSelectedOption.data('city'),
                    stateCode: shipmentSelectedOption.data('state-code')
                };
            }

            var billingAddress = {};
            var billingSelectedOption = $('#billingAddressSelector').find(':selected');
            var isBillingPostalCodeValid;
            var isBillingAddressOneValid;
            var isBillingAddressTwoValid;
            var isBillingCityValid;
            var isBillingCountryValid;
            if ($('#billingAddressSelector').hasClass('d-none')) {
                isBillingPostalCodeValid = validateInput($('#billingZipCode'));
                isBillingAddressOneValid = validateInput($('#billingAddressOne'));
                isBillingAddressTwoValid = validateInput($('#billingAddressTwo'));
                isBillingCityValid = validateInput($('#billingAddressCity'));
                isBillingCountryValid = validateInput($('#billingState'));
                if (!isBillingPostalCodeValid || !isBillingAddressOneValid || !isBillingAddressTwoValid || !isBillingCityValid || !isBillingCountryValid) {
                    return;
                }
                billingAddress = {
                    postalCode: $('#billingZipCode').val(),
                    address1: $('#billingAddressOne').val(),
                    address2: $('#billingAddressTwo').val(),
                    city: $('#billingAddressCity').val(),
                    stateCode: $('#billingState').val()
                };
            } else {
                billingAddress = {
                    postalCode: billingSelectedOption.data('postal-code'),
                    address1: billingSelectedOption.data('address1'),
                    address2: billingSelectedOption.data('address2'),
                    city: billingSelectedOption.data('city'),
                    stateCode: billingSelectedOption.data('state-code')
                };
            }

            var requestObject = {
                sid: $(this).attr('data-subscriptionId'),
                oid: $(this).attr('data-orderId'),
                replacementConfiguration: $('input[name=replacementConfiguration]:checked').val(),
                newTitle: $('#subscriptionTitleId').val(),
                updateProductModels: JSON.stringify(updateProductModels),
                deliveryAddress: JSON.stringify(deliveryAddress),
                billingAddress: JSON.stringify(billingAddress),
                instructionsOption: $('.shippingInstructionsOptions option:selected').val(),
                shippingMethod: $('.shippingMethodID').val(),
                shippingWareHouseID: $('.shippingWareHouseID').val(),
                repeatOrder: $('.shippingRepeatOrder option:selected').val(),
                instructions: $('#shippingInstructionsdefault').val(),
                deliveryDate: $('.shippingDeliveryDateEdit').val(),
                braintreePaymentMethodNonce: $('#braintreeCreditCardNonce').val(),
                cardOwner: $("#braintreeCardHolder").val(),
                selectedCreditCardUuid: $('select[name=braintreeCreditCardList] option').filter(':selected').val(),
                paymentMethodUUID: $('select[name=braintreePaypalAccountList] option').filter(':selected').val(),
                isPayPal: $('.payment-information').attr('data-payment-method-id') == 'PayPal'
            };

            if (data) {
                requestObject.paypalEmail = data.get("dwfrm_braintreepaypalaccount_email");
                requestObject.paypalNonce = data.get("dwfrm_braintreepaypalaccount_nonce");
                requestObject.paypalAddress = data.get("dwfrm_braintreepaypalaccount_addresses");
                requestObject.paypalShippingAddress = data.get("dwfrm_braintreepaypalaccount_shippingAddress");
            }

            $.ajax({
                type: "POST",
                url: $(this).attr("data-link"),
                data: requestObject
            }).done(function(response) {
                if (response.error) {
                    $.spinner().stop();
                    $('.braintree-loader-container').hide()
                    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                        'fade show" role="alert">' +
                        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                        '<span aria-hidden="true">&times;</span>' +
                        '</button>' + response.errorMessage + '</div>';

                    $('.error-messaging').append(errorHtml);
                    $('.error-messaging').show();
                    if ($('.payment-information').attr('data-payment-method-id') == 'PayPal') {
                        $('.js_braintree_accountPaypalButton_wrapper').removeClass('d-none');
                    } else {
                        $('.braintreeCreditCardBtn').show();
                    }
                } else if (response.redirectUrl) {
                    if ($('.success-message').length == 0) {
                        $('body').append(
                            '<div class="success-message"></div>'
                        );
                    }

                    $('.success-message')
                        .append('<div class="success-alert text-center alert-success role="alert">Changes saved</div>');

                    setTimeout(() => {
                        $('.success-message').remove();
                        window.location.replace(response.redirectUrl);
                        window.location.replace(response.redirectUrl);
                    }, 3000);
                }
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize change product form
     */
    SmartOrderRefill.initChangeProductForm = function () {
        $(document).off("click", ".change-item").on("click", ".change-item", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#changeproduct"),
                options = SmartOrderRefill.ModalOptions.changeProductFormOptions;

            $container.load(url, function () {
                SmartOrderRefill.CreateModal($container, options);
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize dialog for update credit card view
     */
    SmartOrderRefill.initUpdateCreditCardForm = function () {
        $(document).off("click", ".update-card").on("click", ".update-card", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#updatecreditcard"),
                options = SmartOrderRefill.ModalOptions.updateCreditCardFormOptions;

            $container.load(url, function () {
                SmartOrderRefill.CreateModal($container, options);
                $(".choose-card-item").on("click", function () {
                    $(this).find("input[type=\"radio\"]").prop("checked", true);
                });
                $("select[id$='_updatecard_expiration_month']").val(parseInt($("#updatecreditcard").find(".expiration-date-wrapper").attr("data-expmonth"), 10));
                $("select[id$='_updatecard_expiration_year']").val($("#updatecreditcard").find(".expiration-date-wrapper").attr("data-expyear"));
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize update refill
     */
    SmartOrderRefill.initUpdateRefill = function () {
        $(document).on("click", ".update-refill", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                item = getURLParameter(url, "item"),
                periodicity = $("#select-everydelivery-" + item).val(),
                interval = (periodicity == "month") ? $("#sorMonth-" + item).val() : $("#sorWeek-" + item).val();
            $.ajax({
                type: "POST",
                url: url,
                data: {
                    periodicity: periodicity,
                    interval: interval
                }
            }).done(function (response) {
                handleRefillResponse(response);
            }).fail(function () {
                handleRefillError();
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize credit card expiration warning
     */
    SmartOrderRefill.initCreditCardExpirationWarning = function () {
        $(document).on("change", ".pt_checkout [name$=\"creditCard_expiration_month\"], .pt_checkout [name$=\"creditCard_expiration_year\"]", function () {
            var month = $(".pt_checkout [name$=\"creditCard_expiration_month\"]").val(),
                year = $(".pt_checkout [name$=\"creditCard_expiration_year\"]").val();
            toggleExpirationWarning(month, year);
        });
        $(document).on("change", "#checkout-main [name$=\"creditCardFields_expirationMonth\"], #checkout-main [name$=\"creditCardFields_expirationYear\"]", function () {
            var month = $("#checkout-main [name$=\"creditCardFields_expirationMonth\"]").val(),
                year = $("#checkout-main [name$=\"creditCardFields_expirationYear\"").val();
            toggleExpirationWarning(month, year);
        });

        function toggleExpirationWarning(month, year) {
            if (typeof month !== "undefined" && typeof year !== "undefined" && month && year) {
                var expirationDate = new Date(new Date(year, month).setDate(0)),
                    currentDate = new Date(),
                    nextYear = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate() - 1);

                if ((expirationDate - nextYear) <= 0) {
                    $(".credit_card_expiration_warning").show();
                } else {
                    $(".credit_card_expiration_warning").hide();
                }
            }
        }
    };

    /**
     * @private
     * @function
     * @description initialize reactivate order view
     */
    SmartOrderRefill.initReactivateOrderView = function () {
        $(document).off("click", ".order.reactivate").on("click", ".order.reactivate", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                order = getURLParameter(url, "sid");
            $.ajax({
                type: "POST",
                url: url,
                data: {
                    order: order
                }
            }).done(function (response) {
                if (response) {
                    if (!response.success) {
                        var $container = SmartOrderRefill.creatContainer("#reactivate-order");
                        $container.html("<h3>" + SmartOrderRefillSettings.Resources.SOR_REACTIVE_ORDER_MESSAGE + "</h3>");
                        SmartOrderRefill.CreateModal($container, SmartOrderRefill.ModalOptions.reactivateOrderViewOptions);
                    } else {
                        window.location = SmartOrderRefillSettings.Urls.manageOrders;
                    }
                } else {
                    SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                }
            }).fail(function () {
                SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
            });
        });
    };

    /**
     * @private
     * @function
     * @description initialize reactivate subscription view
     */
    SmartOrderRefill.initReactivateSubscriptionView = function () {
        $(document).off("click", ".reactivatesubscription").on("click", ".reactivatesubscription", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#reactivate-subscription"),
                options = SmartOrderRefill.ModalOptions.reactivateSubscriptionOptions;

            $container.load(url, function () {
                SmartOrderRefill.CreateModal($container, options);
            });
        });
    };
    /* ****** End of Account section ****** */

    /* ******* Start of Cart section ******* */

    SmartOrderRefill.initModifyRefill = function () {
        $(document).on("click", "#modifyRefill", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                $container = SmartOrderRefill.creatContainer("#modify-smart-order-refill");

            $container.load(url, function () {
                SmartOrderRefill.limitCharacters();
                SmartOrderRefill.CreateModal($container, SmartOrderRefill.ModalOptions.modifyRefillOptions);
                // force to load the selected value of sor subscriptions
                $("[name=\"everyDelivery\"]").trigger("change");
                $("#modify-smart-order-refill select").on("change", function () {
                    $("#multipleRefill").prop("checked", true);
                });
            });
        });
    };

    SmartOrderRefill.initRemoveRefill = function () {
        $(document).on("click", "#removeRefill", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                cartShow = SmartOrderRefillSettings.Urls.cartShow;

            $.ajax({
                type: "GET",
                url: url,
                success: function (response) {
                    if (response && response.success) {
                        window.location = cartShow;
                    } else {
                        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                    }
                }
            });
        });
    };

    SmartOrderRefill.initLoginFromCart = function () {
        $(document).on("click", "#message_wrapper a", function () {
            var $container = SmartOrderRefill.creatContainer("#sorlogin"),
                options = {
                    modal: true,
                    width: "800px",
                    open: function () {
                        // This is intentional
                    }
                };
            $container.load(SmartOrderRefillSettings.Urls.loginFromCartPage, function () {
                SmartOrderRefill.limitCharacters();
                SmartOrderRefill.CreateModal($container, options);
            });
        });
        $(document).on("click", "#sorlogin button", function (e) {
            e.preventDefault();
            var form = $(this).parents("form:first");
            if (SmartOrderRefill.validateForm(form)) {
                var url = form.attr("action"),
                    data = {};
                form.serializeArray().map(function (param) {
                    data[param.name] = param.value;
                });

                $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    success: function (response) {
                        if (!response) {
                            SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                        } else if (response.success) {
                            if ($("#loginFromCartError").length) {
                                $("#loginFromCartError").remove();
                            }
                            window.location = response.url;
                        } else if (!$("#loginFromCartError").length) {
                            form.prepend("<div id=\"loginFromCartError\" class=\"error-form\">" + SmartOrderRefillSettings.Resources.SOR_LOGINFROMCART_ERROR + "</div>");
                        }
                    }
                });
            }
        });
    };

    /* ******* End of Cart section ******* */

    /* ******* Start of Product section ******* */

    SmartOrderRefill.initializePdp = function () {
        initOptions();

        $(document).on("click", "#pdpMain .product-add-to-cart select#SorDeliveryMonthInterval", function () {
            $("input[name=\"hasSmartOrderRefill\"]").val(["true"]);
        });

        $("input[name=hasSmartOrderRefill]").change(function () {
            hideOrShowSchedules($(this).is(":checked") ? "true" : "false", $(this).closest('.product-detail'));
        }).change();

        //  Show or hide the schedule select based on user selection.
        function hideOrShowSchedules(value, $context) {
            if (value === "true") {
                if (window.innerWidth < 568) {
                    $(".smart-order-refill-period", $context).attr("style", "margin: 15px 20px");
                }
                $(".smart-order-refill-period", $context).attr("style", "display:inline-block");
                $("#everyDelivery", $context).trigger("change");
                $(".weeks-block", $context).addClass('show');

                // Get the default interval option value from the checkbox data attribute
                var defaultIntervalOption = $("#OsfSorRefillProductYes", $context).data("default-interval-option");
                $("button.btn-week.js-sor-interval-pdp", $context).removeClass("active");
                $("button.btn-week.js-sor-interval-pdp[data-value='" + defaultIntervalOption + "']", $context).addClass("active");
            } else {
                $(".or-cancel-edit-text", $context).attr("style", "margin-top:10px");
                $(".smart-order-refill-period", $context).attr("style", "display:none");
                $(".weeks-block", $context).removeClass('show');
                if ($("input[name=variantSelected]").val()) {
                    $("#add-to-cart", $context).removeAttr("disabled");
                }
            }
        }

        function initOptions() {
            if (!$(".smart-order-refill-options").data("sorOneTime")) {
                $(".smart-order-refill-options [for=\"OsfSorRefillProductNo\"], .smart-order-refill-options [for=\"OsfSorRefillProductYes\"]").hide();
                $("#OsfSorRefillProductYes").prop("checked", true).change();
            }
            if ($(".smart-order-refill-period p").data("disableDropdown")) {
                $("#SorDeliveryWeekInterval, #SorDeliveryMonthInterval").css({
                    display: "none"
                });
            }
        }

        $(document).on("change", "[name=\"everyDelivery\"]", function () {
            if ($('.js-repeat-order').hasClass('noInferiorBorder')) {
                $('.js-repeat-order').removeClass('noInferiorBorder');
            }
            else {
                $('.js-repeat-order').addClass('noInferiorBorder');
            }
            if ($(this).val() === "month") {
                $("select[name=SorDeliveryWeekInterval]").hide();
                $("select[name=SorDeliveryMonthInterval]").show();
                if ($(".smart-order-refill-period p").data("disableDropdown")) {
                    $("#SorDeliveryWeekInterval, #SorDeliveryMonthInterval").css({
                        display: "none"
                    });
                }
            } else if ($(this).val() === "week") {
                $("select[name=SorDeliveryMonthInterval]").hide();
                $("select[name=SorDeliveryWeekInterval]").show();
                if ($(".smart-order-refill-period p").data("disableDropdown")) {
                    $("#SorDeliveryWeekInterval, #SorDeliveryMonthInterval").css({
                        display: "none"
                    });
                }
            }
        });

        $("[name=\"everyDelivery\"], select[name=\"SorDeliveryWeekInterval\"], select[name=\"SorDeliveryMonthInterval\"]").on("change", function () {
            var everyDeliveryHasValueSelected = $("[name=\"everyDelivery\"]").val(),
                weekIntervalHasValueSelected = $("select[name=SorDeliveryWeekInterval]").val(),
                monthIntervalHasValueSelected = $("select[name=SorDeliveryMonthInterval]").val(),
                sorDataInformed = everyDeliveryHasValueSelected || (weekIntervalHasValueSelected || monthIntervalHasValueSelected),
                variantSelected = $("input[name=variantSelected]").val(),
                shouldEnableAddToCartButton = sorDataInformed && variantSelected;

            if (shouldEnableAddToCartButton) {
                $("#add-to-cart").removeAttr("disabled");
            } else {
                $("#add-to-cart").attr("disabled", "disabled");
            }
        });

        $("body").on("product:afterAddToCart", function (e, response) {
            if (!response.error) {
                $.ajax({
                    type: "POST",
                    url: SmartOrderRefillSettings.Urls.updateRefillData,
                    data: {
                        action: "update",
                        hasSmartOrderRefill: $("[name=\"hasSmartOrderRefill\"]").filter(":checked").val(),
                        everyDelivery: $("[name=\"everyDelivery\"]").val(),
                        SorDeliveryWeekInterval: $("[name=\"SorDeliveryWeekInterval\"]").val(),
                        SorDeliveryMonthInterval: $("[name=\"SorDeliveryMonthInterval\"]").val(),
                        liuuid: response.pliUUID
                    }
                });
            }
        });
        $("body").on("product:afterAttributeSelect", function (e, response) {
            if (response.data && response.data.product && response.data.product.id) {
                $.ajax({
                    type: "GET",
                    url: SmartOrderRefillSettings.Urls.updatePDPOptions,
                    data: {
                        pid: response.data.product.id
                    },
                    success: function (innerResponse) {
                        var refillOptions = $(innerResponse).filter(".smart-order-refill-options");
                        var everyDelivery = refillOptions.find("[name=\"everyDelivery\"]").val();
                        var weekInterval = refillOptions.find("[name=\"SorDeliveryWeekInterval\"]").val();
                        var monthInterval = refillOptions.find("[name=\"SorDeliveryMonthInterval\"]").val();

                        var sorPrice = refillOptions.find(".sor-price");
                        var sorPriceMessage = refillOptions.find(".sor-price-message");

                        $("[name=\"everyDelivery\"]").val(everyDelivery).change();
                        $("[name=\"SorDeliveryWeekInterval\"]").val(weekInterval).change();
                        $("[name=\"SorDeliveryMonthInterval\"]").val(monthInterval).change();
                        $(".smart-order-refill-options .sor-price").remove();
                        $(".smart-order-refill-options .sor-price-message").remove();
                        if (sorPrice.length) {
                            $(".smart-order-refill-options").append(sorPrice);
                            $(".smart-order-refill-options").append(sorPriceMessage);
                        }
                        initOptions();
                    }
                });
            }
        });
        if ($('input[name=hasSmartOrderRefill]').length > 0) {
            hideOrShowSchedules($("input[name=hasSmartOrderRefill]:checked").val());
        }
    };

    // generic method for functions without Ajax calls
    SmartOrderRefill.initLinkModel = function () {
        $(document).off("click", ".sorshowmodal").on("click", ".sorshowmodal", function (e) {
            e.preventDefault();
            var url = $(this).attr("data-link"),
                title = $(this).attr("data-title"),
                content = $(this).attr("data-content"),
                yes = $(this).attr("data-yes"),
                no = $(this).attr("data-no"),
                $container = SmartOrderRefill.creatContainer("#link-model"),
                context =  $(this).attr("data-context"),
                options = {
                    url: url,
                    innerUrl: $(this).attr("data-innerurl"),
                    autoOpen: true,
                    modal: true,
                    title: title,
                    width: "400px",
                    buttons: {},
                    context: context
                };

            if ($(this).hasClass('add-products-to-subscription')) {
                options.addnewproducts = true;
                $container.addClass('add-products-subscription-container');
            }

            if ($(this).hasClass('cancelsubscription')) {
                options.cancel = true;
            }

            if ($(this).hasClass('pausesubscription')) {
                options.pause = true;
                $container.addClass('pause-subscription-container');
            }

            if ($(this).hasClass('renewsubscription')) {
                options.renew = true;
                $container.addClass('renew-subscription-container');
            }

            if (yes) {
                if (options.innerUrl) {
                    if (options.addnewproducts) {
                        options.buttons[yes] = SmartOrderRefill.ButtonFunctions.redirectToUrl.bind($container, options.innerUrl);
                    } else if (options.renew) {
                        options.buttons[yes] = SmartOrderRefill.ButtonFunctions.reactivateSubscriptionSave.bind($container, options.innerUrl);
                    } else {
                        options.buttons[yes] = SmartOrderRefill.ButtonFunctions.linkModelReloadPage.bind($container, options.innerUrl);
                    }
                } else {
                    options.buttons[yes] = SmartOrderRefill.ButtonFunctions.linkModelYes.bind($container, url);
                }
            }
            if (no) {
                options.buttons[no] = SmartOrderRefill.CloseModal;
            }

            $container.html("<h3>" + content + "</h3>");
            if ($(this).hasClass('pausesubscription') && $(this).attr('data-isFrozen') == 'true') {
                var frozenMessage = $(this).attr('data-frozen');
                var warningBlock = `
                    <div class="alert alert-sm alert-warn warning-message">
                        <span class="warning-icon"></span>
                        <span class="text">${frozenMessage}</span>
                    </div>
                `;
                $container.append(warningBlock);
            }

            SmartOrderRefill.CreateModal($container, options);
        });
    };

    // generic method for error messages
    SmartOrderRefill.initErrorModal = function (content, refresPage) {
        var $container = SmartOrderRefill.creatContainer("#error-model"),
            options = {
                autoOpen: true,
                modal: true,
                title: SmartOrderRefillSettings.Resources.SOR_ERROR_TITLE,
                width: "400px",
                buttons: {
                    SOR_GLOBAL_OK: SmartOrderRefill.ButtonFunctions.reloadDashboard.bind($container, refresPage)
                }
            };

        $container.html("<h3>" + content + "</h3>");
        SmartOrderRefill.CreateModal($container, options);
    };


    // ***** BUTTONS FUNCTIONS ******
    SmartOrderRefill.ButtonFunctions = {
        linkModelReloadPage: function (url) {
            $.ajax({
                type: "GET",
                url: url,
                success: function (response) {
                    if (response && response.success) {
                        location.reload();
                    } else {
                        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                    }
                }
            });
            SmartOrderRefill.CloseModal(this);
        },

        redirectToUrl: function (url) {
            window.location.href = url;
        },

        // reactivateSubscription
        reactivateSubscriptionSave: function () {
            var $form = $("#renewSubscription");
            var nextDeliveryDate = $('#shippingDeliveryDate').val();
            var sid = $('.renewsubscription').attr('data-sid');

            if ($form && $form.attr("action") && sid && nextDeliveryDate) {
                var url = $form.attr("action") + '?sid=' + sid + '&nextDeliveryDate=' + nextDeliveryDate + '&reactiveType=remaining';
                setTimeout(function () {
                    $.ajax({
                        type: "POST",
                        url: url
                    }).done(function (response) {
                        if (response && response.success) {
                            SmartOrderRefill.CloseModal();
                            window.location = SmartOrderRefillSettings.Urls.manageOrders;
                        } else {
                            SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                        }
                    }).fail(function () {
                        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                    });
                }, 1000);
            } else if (!nextDeliveryDate) {
                $('.error-block').removeClass('d-none');
                $('.content-error-msg').html($('.renewsubscription').attr("data-content"));
            }
        },
        reloadDashboard: function (refresPage) {
            if (refresPage) {
                window.location = SmartOrderRefillSettings.Urls.manageOrders;
            }
        },
        // initReactivateOrderView
        reactivateOrderViewOk: function () {
            setTimeout(function () {
                $.ajax({
                    type: "POST",
                    url: SmartOrderRefillSettings.Urls.cancelOneOrder,
                    data: {
                        sid: order // eslint-disable-line no-undef
                    }
                }).done(function (response) {
                    if (response && response.success) {
                        SmartOrderRefill.CloseModal();
                        window.location = SmartOrderRefillSettings.Urls.manageOrders;
                    } else {
                        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                    }
                }).fail(function () {
                    SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                });
            }, 1000);
        },

        linkModelYes: function (url) {
            $.ajax({
                type: "GET",
                url: url,
                success: function (response) {
                    if (response) {
                        if (response.success) {
                            window.location = SmartOrderRefillSettings.Urls.manageOrders;
                        } else if (response.message) {
                            SmartOrderRefill.initErrorModal(response.message, true);
                        } else {
                            SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, true);
                        }
                    }
                }
            });
            SmartOrderRefill.CloseModal();
        },

        skipOrderViewYes: function (url) {
            $.ajax({
                type: "GET",
                url: url,
                success: function (response) {
                    handleRefillResponse(response);
                }
            });
            SmartOrderRefill.CloseModal();
        },

        // initUpdateCreditCardForm
        updateCreditCardFormSave: function () {
            var $form = $("#editCreditCard");
            var formValid = SmartOrderRefill.validateForm($form);
            var today = new Date();
            today.setHours(0, 0, 0);
            var expirationDate = new Date();
            var year = parseInt($form.find("[name$='updatecard_expiration_year']").val(), 10);
            var month = parseInt($form.find("[name$='updatecard_expiration_month']").val(), 10);
            if (year && month) {
                expirationDate.setMonth(month - 1);
                expirationDate.setFullYear(year);
                expirationDate.setHours(0, 0, 0);
                if (expirationDate.getTime() <= today.getTime()) {
                    formValid = false;
                    alert(SmartOrderRefillSettings.Resources.SOR_CREDITCARD_ERROR); // NOSONAR
                }
            }

            if (formValid) {
                if ($form.find(".choose-card-wrapper").length > 0) {
                    var $cardSelects = $("#editCreditCard").find("[name$=\"updatecard_confirm\"]");
                    if ($form.find("[name$=\"updatecard_confirm\"]").filter(":checked").length) {
                        $cardSelects.each(function () {
                            var $cardSelect = $(this);
                            if (!$cardSelect.prop("checked")) {
                                $cardSelect.parents(".choose-card-item").remove();
                            }
                        });
                    } else {
                        return false;
                    }
                }
                setTimeout(function () {
                    $.ajax({
                        type: "POST",
                        url: $form.attr("action"),
                        data: $form.serialize()
                    }).done(function (response) {
                        if (response && response.success) {
                            SmartOrderRefill.CloseModal();
                            window.location = SmartOrderRefillSettings.Urls.manageOrders;
                        } else {
                            alert(SmartOrderRefillSettings.Resources.SOR_CREDITCARD_ERROR); // NOSONAR
                        }
                    }).fail(function () {
                        alert(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR); // NOSONAR
                    });
                }, 1000);
            }
        },

        // initChangeProductForm
        changeProductFormSave: function () {
            var $form = $("#editProductForm");
            var product = $(".product-select").val();

            setTimeout(function () {
                $.ajax({
                    type: "POST",
                    url: $form.attr("action"),
                    data: {
                        newProduct: product
                    }
                }).done(function (response) {
                    if (response && response.success) {
                        SmartOrderRefill.CloseModal.call($container); // eslint-disable-line no-undef
                        window.location = SmartOrderRefillSettings.Urls.manageOrders;
                    } else {
                        alert(SmartOrderRefillSettings.Resources.SOR_PRODUCT_ERROR); // NOSONAR
                    }
                }).fail(function () {
                    SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                });
            }, 1000);
        },

        // initAddressChangeForm
        addressChangeFormSave: function () {
            var $form = $("#editAddressForm");
            var formValid = SmartOrderRefill.validateForm($form);
            var target = SmartOrderRefill.addressChangeInfo.target;
            var urlview = SmartOrderRefill.addressChangeInfo.urlview;
            var $container = SmartOrderRefill.addressChangeInfo.container;

            setTimeout(function () {
                if (!formValid) {
                    return;
                }
                $.ajax({
                    type: "POST",
                    url: $form.attr("action"),
                    data: $form.serialize()
                }).done(function (response) {
                    if (response) {
                        if (response.success) {
                            var bootstrapModalBody = $(target).find(".modal .modal-body");
                            if (bootstrapModalBody.length > 0) {
                                target = bootstrapModalBody;
                            }
                            $(target).load(urlview, function () {
                                SmartOrderRefill.limitCharacters();
                            });
                            SmartOrderRefill.CloseModal.call($container);
                        } else if ($("input[name$=\"_postal\"]:invalid").length) {
                            $("input[name$=\"_postal\"]").parent().append("<label class=\"error\">" + SmartOrderRefillSettings.Resources.INVALID_ZIP + "</label>");
                        }
                    } else {
                        alert(SmartOrderRefillSettings.Resources.SOR_ADDRESS_ERROR); // NOSONAR
                    }
                }).fail(function () {
                    alert(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR); // NOSONAR
                });
            }, 1000);
        },

        modifyRefillUpdate: function () {
            var url = $("#modify-smart-order-refill form").attr("action");
            var data = {
                hasSmartOrderRefill: $("[name=\"hasSmartOrderRefill\"]").filter(":checked").val(),
                everyDelivery: $("[name=\"everyDelivery\"]").val(),
                SorDeliveryWeekInterval: $("[name=\"SorDeliveryWeekInterval\"]").val(),
                SorDeliveryMonthInterval: $("[name=\"SorDeliveryMonthInterval\"]").val()
            };
            $.ajax({
                type: "POST",
                url: url,
                data: data,
                success: function (response) {
                    if (response && response.success) {
                        window.location = SmartOrderRefillSettings.Urls.cartShow;
                    } else {
                        SmartOrderRefill.initErrorModal(SmartOrderRefillSettings.Resources.SOR_UNEXPECTED_ERROR, false);
                    }
                }
            });
        }
    };


    // ******  OPTIONS  *******

    SmartOrderRefill.ModalOptions = {
        // initReactivateSubscriptionView
        reactivateSubscriptionOptions: {
            autoOpen: true,
            bgiframe: true,
            title: SmartOrderRefillSettings.Resources.SOR_REACTIVE_SUBSCRIPTION_TITLE,
            modal: true,
            emptyOnClose: false,
            width: "400px",
            buttons: {
                SOR_GLOBAL_CANCEL: SmartOrderRefill.CloseModal,
                SOR_GLOBAL_SAVE: SmartOrderRefill.ButtonFunctions.reactivateSubscriptionSave
            }
        },

        // initReactivateOrderView
        reactivateOrderViewOptions: {
            autoOpen: true,
            bgiframe: true,
            title: SmartOrderRefillSettings.Resources.SOR_REACTIVE_ORDER_TITLE,
            modal: true,
            emptyOnClose: false,
            width: "400px",
            buttons: {
                SOR_GLOBAL_OK: SmartOrderRefill.ButtonFunctions.reactivateOrderViewOk
            }
        },

        // initUpdateCreditCardForm
        updateCreditCardFormOptions: {
            autoOpen: true,
            bgiframe: true,
            title: SmartOrderRefillSettings.Resources.SOR_UPDATE_CREDIT_CARD_TITLE,
            modal: true,
            emptyOnClose: false,
            width: "600px",
            buttons: {
                SOR_GLOBAL_CANCEL: SmartOrderRefill.CloseModal,
                SOR_GLOBAL_SAVE: SmartOrderRefill.ButtonFunctions.updateCreditCardFormSave
            }
        },
        // initChangeProductForm
        changeProductFormOptions: {
            autoOpen: true,
            bgiframe: true,
            modal: true,
            emptyOnClose: false,
            width: "400px",
            title: "Change product",
            buttons: {
                SOR_GLOBAL_CANCEL: SmartOrderRefill.CloseModal,
                SOR_GLOBAL_SAVE: SmartOrderRefill.ButtonFunctions.changeProductFormSave
            }
        },
        // initSubscriptionView
        subscriptionViewOptions: {
            autoOpen: true,
            modal: true,
            dialogClass: "smart-order-refill-modal",
            width: "700px",
            title: SmartOrderRefillSettings.Resources.SOR_DIALOG_SUBSCRIPTION,
            buttons: {
                SOR_GLOBAL_CLOSE: SmartOrderRefill.CloseModal
            }
        },
        // initOrderView
        orderViewOptions: {
            draggable: false,
            resizable: false,
            dialogClass: "smart-order-refill-modal",
            autoOpen: true,
            modal: true,
            width: "700px",
            title: SmartOrderRefillSettings.Resources.SOR_DIALOG_ORDER,
            buttons: {
                SOR_GLOBAL_CLOSE: SmartOrderRefill.CloseModal
            }
        },
        modifyRefillOptions: {
            draggable: false,
            resizable: false,
            dialogClass: "smart-order-refill-modal",
            modal: true,
            title: SmartOrderRefillSettings.Resources.SOR_MODIFY_SMART_ORDER_REFILL,
            width: "600px",
            buttons: {
                SOR_GLOBAL_UPDATE: SmartOrderRefill.ButtonFunctions.modifyRefillUpdate
            }
        },
        // initAddressChangeForm
        addressChangeFormOptions: function (target, urlview, $container) {
            SmartOrderRefill.addressChangeInfo = {
                target: target,
                urlview: urlview,
                container: $container
            };
            return {
                autoOpen: true,
                bgiframe: true,
                modal: true,
                emptyOnClose: false,
                width: "400px",
                title: "Change address",
                buttons: {
                    SOR_GLOBAL_CANCEL: SmartOrderRefill.CloseModal,
                    SOR_GLOBAL_SAVE: SmartOrderRefill.ButtonFunctions.addressChangeFormSave
                }
            };
        },
        linkModelYes: function (url) {
            $.ajax({
                type: "GET",
                url: url,
                success: function (response) {
                    handleRefillResponse(response);
                }
            });
            SmartOrderRefill.CloseModal(this);
        }

    };


    SmartOrderRefill.initAccountSorView = function () {
        $(".show-hide-orders").each(function () {
            $(this).on("click", function () {
                $(this).closest(".subscriptionSection").find(".subscriptionOrders").slideToggle("slow");
            });
        });
    };

    SmartOrderRefill.initChangeProductQuantity = function () {
        $(document).on('click', '.button-plus-product, .button-minus-product', function (e) {
            e.preventDefault();
            var $input = $(this).siblings('.quantity-input');
            var stepValue = $input.attr('step');
            var minValue = $input.attr('min');
            var maxValue = $input.attr('max');
            var currentval = $input.val();

            if ($(e.currentTarget).hasClass('button-plus-product') && currentval < +maxValue) {
                $input.val(+currentval + +stepValue);
            } else if ($(e.currentTarget).hasClass('button-minus-product') && currentval > +minValue) {
                $input.val(+currentval - +stepValue);
            }
            $input.trigger("change");

            // check frozen products quantity
            var frozenProductsActionUrl = $('.products').data('frozenurl');
            var productData;
            var productsData = [];
            $('.product-item').each(function () {
                productData = {
                    pid: $(this).find('input').data('pid'),
                    quantity: $(this).find('.product-quantity .quantity-input').val()
                };
                productsData.push(productData);
            });
            var sid = $(this).closest('.products').data('sid');
            $.ajax({
                url: frozenProductsActionUrl,
                type: 'POST',
                dataType: 'json',
                data: {productsData : JSON.stringify(productsData), sid : sid},
                success: function (response) {
                    if (response.isValidFrozenWeight) {
                        $('#save-subscription-changes').addClass('save-subscription-changes').removeClass('disabled');
                        $('.frozen-warning-message').addClass('d-none');
                    } else {
                        $('#save-subscription-changes').removeClass('save-subscription-changes').addClass('disabled');
                        $('.frozen-warning-message').removeClass('d-none');
                    }
                }
            });
        });
    };

    SmartOrderRefill.initRemoveProductFromSubscription = function () {
        $(document).on('click', '.remove-subscription-products', function (e) {
            e.preventDefault();
            var removeProductUrl = $(this).closest('.products').data('removeproducturl');
            var pid = $(this).closest('.product-item').data('pid');
            var sid = $(this).closest('.products').data('sid');
            var customerEmail = $('.subcription-detail').data('customeremail');
            var requestObject = {
                pid: pid,
                sid: sid,
                customerEmail: customerEmail
            };
            $.ajax({
                url: removeProductUrl,
                type: 'POST',
                dataType: 'json',
                data: requestObject,
                success: function (response) {
                    if (response && response.success) {
                        if (response.productsQuantity > 0) {
                            $('.product-item[data-pid="' + pid + '"]').remove();
                            if (!response.isValidFrozenWeight) {
                                $('.frozen-warning-message').removeClass('d-none');
                                $('#save-subscription-changes').removeClass('save-subscription-changes').addClass('disabled');
                            } else {
                                $('.frozen-warning-message').addClass('d-none');
                                $('#save-subscription-changes').addClass('save-subscription-changes').removeClass('disabled');
                            }
                        } else {
                            window.location.href = $('.products').data('nomoreproductsurl');
                        }
                    }
                }
            });
        });
    };

    SmartOrderRefill.initOpenSubscriptionDetails = function () {
        $(document).on('click', '.subscription-section', function (e) {
            var redirectUrl = $(this).data('subscriptionurl');
            window.location.replace(redirectUrl);
        });

        // Update total price
        var subscriptions = [];
        if ($('.active-subscription').length > 0) {
            subscriptions = $('.active-subscription');
        } else {
            if ($('.subscriptions-mobile').is(':visible')) {
                subscriptions = $('.subscriptions-mobile .subscription-section');
            } else {
                subscriptions = $('.subscriptions .subscription-section');
            }
        }

        for (var i = 0; i < subscriptions.length; i++) {
            var $currentSubs = $(subscriptions[i]);

            $.ajax({
                url: $currentSubs.attr('data-updatesubscriptionprice'),
                type: 'GET',
                success: function (response) {
                    if (response.id && response.price) {
                        $('.' + response.id).find('.price .value').html(response.price);
                    }
                }
            });
        }
    };

    SmartOrderRefill.initChangeDeliveryDateModal = function () {
        const urlChangeDeliveryData = $('#urlChangeDeliveryData');
        const searchParams = new URLSearchParams(window.location.href);

        if (urlChangeDeliveryData.length > 0 && searchParams.has('changeDate') && searchParams.get('changeDate') === 'true') {
            initializeChangeDeliveryDateModal(urlChangeDeliveryData.val());
        }

        $(document).on('click', '.js-dashboard-subscription-edit-delivery-button', function (e) {
            initializeChangeDeliveryDateModal($(e.currentTarget).attr('data-url'))
        });

        $(document).on('click', '.btn-update-delivery-date', function () {
            const $form = $('#changeDeliveryDateForm');
            const $modal = $('#changeDeliveryDateModal');
            const $error =  $modal.find('.error-block');

            $modal.spinner().start();
            $error.addClass('d-none');

            $.ajax({
                url: $form.attr('action'),
                type: 'POST',
                dataType: 'JSON',
                data: $form.serialize(),
                success: function (response) {
                    if (response.success) {
                        if (new URLSearchParams(window.location.href).has('changeDate')) {
                            window.location.href = removeChangeDateUrlParam();
                        } else {
                            location.reload();
                        }
                    } else {
                        $error.removeClass('d-none');
                    }

                    $modal.spinner().stop();
                },
                error: function () {
                    $error.removeClass('d-none');
                    $modal.spinner().stop();
                }
            });
        });

        $(document).on('click', '.btn-cancel-update-delivery-date', function () {
            if (new URLSearchParams(window.location.href).has('changeDate')) {
                window.location.href = removeChangeDateUrlParam();
            } else {
                $('#changeDeliveryDateModal').modal('hide');
            }
        });

        function removeChangeDateUrlParam() {
            var updatedSearchParams = window.location.search.replace(/([?&])changeDate=[^&]+(&|$)/, '$1');
            return (window.location.origin + window.location.pathname + updatedSearchParams).toString().slice(0, -1);
        }

        function initializeChangeDeliveryDateModal(url) {
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    if (response && response.length > 0) {
                        const $container = SmartOrderRefill.creatContainer('#changeDelveryDatesContainer');

                        $container.html(response);
                        $container.find('.modal').modal({
                            backdrop: 'static'
                        });
                        $container.find('.modal').modal('show');
                        $container.find('.modal').on('hidden.bs.modal', function () {
                            $container.remove();
                        });

                        const availableDates = JSON.parse($('#changeDeliveryDateModal').attr('data-available-dates'));
                        const dayOfWeek = new Date(availableDates[0].date).getDay();

                        var datePicker = new Lightpick({
                            field: document.getElementById('subscriptionDeliveryDate'),
                            singleDate: true,
                            enabledDates: availableDates,
                            minDate: moment().startOf('day').add(1, 'days'),
                            maxDate: moment().startOf('day').add(32, 'days'),
                            dropdowns: false,
                            inline: true
                        });

                        datePicker.setDate(new Date($('#changeDeliveryDateModal').attr('data-next-refill-date')));
                        
                        if (dayOfWeek === 0 || dayOfWeek === 6) {
                            $('.calendar-wrapper').addClass('boldWeekend');
                        } else {
                            $('.calendar-wrapper').addClass('boldWeekedays');
                        }
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    };

    /**
     * @private
     * @function
     * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
     */
    SmartOrderRefill.initialize = function () {
        SmartOrderRefill.initSubscriptionView();
        SmartOrderRefill.initOrderView();
        SmartOrderRefill.initAddressChangeForm();
        SmartOrderRefill.initCreditCardExpirationWarning();
        SmartOrderRefill.initChangeProductForm();
        SmartOrderRefill.initReactivateSubscriptionView();
        SmartOrderRefill.initReactivateOrderView();
        SmartOrderRefill.initUpdateProductQuantity();
        SmartOrderRefill.initRemoveProductFromSubscription();
        SmartOrderRefill.initRemoveProduct();
        SmartOrderRefill.initUpdateRefill();
        SmartOrderRefill.initModifyRefill();
        SmartOrderRefill.initRemoveRefill();
        SmartOrderRefill.initLoginFromCart();

        SmartOrderRefill.initLinkModel();

        SmartOrderRefill.initializePdp();
        SmartOrderRefill.initAccountSorView();
        SmartOrderRefill.initOpenSubscriptionDetails();
        SmartOrderRefill.initUpdateSubscription();
        SmartOrderRefill.initChangeProductQuantity();
        SmartOrderRefill.initChangeDeliveryDateModal();
    };


    $(document).ready(function () {
        SmartOrderRefill.initialize();
        if (typeof $.fn.dialog === "undefined" && typeof $.fn.modal !== "undefined") {
            SmartOrderRefillSettings.ModalType = "modal";
        }

        $(".sorlink.visually-hidden").removeClass("visually-hidden");
        window.SmartOrderRefill = SmartOrderRefill;
    });
}(window.jQuery));
